<template>
  <div>
    <div
      id="offcanvasFilters"
      class="offcanvas offcanvas-end offcanvas-end--filters d-flex"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      tabindex="-1"
      aria-labelledby="offcanvasFiltersLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasResearchLineLabel"
          class="offcanvas-title"
        >
          <span class="btn btn-icon btn-icon rounded-circle btn-flat-secondary bg-light-secondary me-2"><i data-feather="check" /></span> Users with same emails found:
        </h3>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body offcanvas-body--view">
        Please, select with which user you want to match this contact
        <div
          v-for="(data, id) in checkedEmails"
          :key="id"
          class="mt-1"
        >
          <div
            v-for="(email, name) in data"
            :key="name"
            class="mt-1"
          >
            <div class="d-flex">
              <div class="more-info">
                <div class="form-check">
                  <input
                    v-model="selectedUser"
                    type="radio"
                    class="form-check-input mb-1"
                    name="selectedUser"
                    :value="id"
                  >
                  <label
                    class="form-check-label"
                    :for="name"
                  >{{ name }}</label>
                </div>
              </div>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>Where</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="data in email"
                  :key="data"
                >
                  <td>{{ data['where'] }}</td>
                  <td>{{ data['email'] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          v-if="selectedUser"
          class="d-flex"
        >
          <button
            type="button"
            class="btn btn-primary"
            @click="matchUser"
          >Select this user & save</button>
        </div>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>
  <!-- -->
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  data() {
    return {
      selectedUser: null,
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/institutionContactEmail',
      checkedEmails: 'institutions/checkedEmails',
    }),
  },
  async mounted() {
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/toggleInstitutionContactEmail', false)
    },
    matchUser() {
      this.$emit('matchUser', this.selectedUser)
      this.$store.dispatch('modals/toggleInstitutionContactEmail', false)
    },
  },
}
</script>
